.page-detail {
  min-height: 100%;
  min-height: -webkit-fill-available;
  padding: 30px 0 90px 0;
  overflow-x: hidden;
}

.container {
  position: relative;
  height: 100%;
  // height: -webkit-fill-available;
}

.image {
  width: calc(100% - 40px);
  position: fixed;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 1;
}

.detail {
  $this: &;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  color: #fff;

  &__image {
    max-width: 180px;
    width: 100%;
    min-height: 200px;
    margin: 0 auto;
  }

  &__name {
    text-align: center;
    font-size: 32px;
    line-height: 1.2;
    white-space: pre;
    user-select: none;
  }

  &__kind {
    margin: 30px 0 0 0;
  }

  &__location {
    margin: 8px 0 0 0;
  }

  &__kind,
  &__location {
    text-align: center;
    font-size: 15px;
    line-height: 1.4;
    user-select: none;
  }

  &__desc {
    position: relative;
    max-width: 500px;
    width: 100%;
    font-size: 20px;
    margin: 60px auto 0 auto;
    line-height: 1.8;
    user-select: none;
    z-index: 1;

    &--answered {
      #{$this}__word {
        span {
          &:first-child {
            opacity: 0;
            visibility: hidden;
          }
          &:nth-child(2) {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }

  &__particles {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 36px;
    height: 36px;
    transform: translate(-50%, -50%);
    pointer-events: none;
    z-index: -1;
  }

  &__particle {
    position: absolute;
    top: 0;
    left: 0;
    width: 36px;
    height: 36px;
    // background-color: #fff;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.85) 0%, rgba(255, 255, 255, 0) 75%);
    border-radius: 50%;

    &:nth-of-type(odd) {
      width: 42px;
      height: 42px;
      background: radial-gradient(circle, rgba(255, 255, 255, 0.85) 0%, rgba(255, 255, 255, 0) 60%);
    }
  }

  &__word {
    position: relative;
    display: inline;

    span {
      display: inline-block;
      line-height: 1.5;
      white-space: nowrap;
      transition: opacity 350ms, visibility 350ms;

      &:nth-child(2) {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
      }
    }

  }

  &__quiz {
    margin: 70px 0 0 0;

    &-wrap {
      position: relative;
      display: flex;
      justify-content: center;
      margin: 30px -10px 0 -10px;
      z-index: 1;

      &--answered {
        #{$this}__quiz-a {
          &--correct {
            border-color: rgba(255, 255, 255, 1);
          }

          .layer-before {
            opacity: 0;
          }

          .layer-after {
            opacity: 1;
          }
        }
      }
    }

    &-q {
      text-align: center;

      p {
        font-size: 20px;
        color: #fff;
        line-height: 1.8;
        user-select: none;
      }
    }

    &-a {
      position: relative;
      width: 150px;
      min-height: 60px;
      margin: 0 10px;
      z-index: 1;

      &:last-child {
        img {
          left: 2px;
        }
      }

      a {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 22px;
        color: #fff;
        background-color: rgba(9, 17, 44, 0.75);
        border: 1px solid rgba(255, 255, 255, 0.4);
        border-radius: 10px;
        line-height: 1.2;
        user-select: none;
        transition: color 350ms, background-color 350ms, border-color 350ms;
      }

      img {
        position: absolute;
        top: 50%;
        left: 12px;
        transform: translate(0, -50%);
        pointer-events: none;
      }

      &--long {
        height: 70px;

        .layer-after {
          padding: 0 10px 0 50px;
        }
      }

      &--smaller {
        a {
          font-size: 16px;
        }
      }

      &--correct {
        .layer-after {
          color: #000;
          background-color: #fff;
        }
      }

      .layer-before,
      .layer-after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: opacity 500ms;
      }

      .layer-before {
        padding: 0 30px;
      }

      .layer-after {
        border-radius: 10px;
        opacity: 0;
      }
    }
  }

  &__close {
    position: fixed;
    top: 20px;
    right: 10px;
    width: 60px;
    height: 60px;
    z-index: 2;

    a {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(#292F43, 0.5);
      border: 1px solid rgba(255, 255, 255, 0.4);
      border-radius: 50%;
    }
  }

  &__scroll {
    position: fixed;
    bottom: 20px;
    right: 10px;
    width: 60px;
    height: 60px;
    z-index: 2;

    a {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(#292F43, 0.5);
      border: 1px solid rgba(255, 255, 255, 0.4);
      border-radius: 50%;
    }
  }
}