.page-list {

}

.head {
  position: fixed;
  top: 0;
  left: 20px;
  width: calc(100% - 40px);
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  background: linear-gradient(90deg, rgba(21, 44, 122, 0) 0%, rgba(21, 44, 122, 0.4) 13%, rgba(21, 44, 122, 0.4) 87.5%, rgba(21, 44, 122, 0) 100%);
  backdrop-filter: blur(10px);
  z-index: 1;

  &__text {
    display: flex;
    align-items: baseline;
    color: #fff;
    user-select: none;

    p {
      font-size: 18px;
    }

    span {
      font-size: 14px;
      margin: 0 0 0 10px;
    }

    b {
      display: inline-block;
      font-size: 20px;
      transform: translate(0, 2px);
      opacity: 0.8;
    }
  }

  &__btn {
    width: 30px;
    height: 30px;

    a {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      padding: 5px;
    }

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 25px;
      height: 1px;
      background-color: #fff;

      &:first-child {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:nth-child(2) {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 100px 0 0 0;
  max-width: 760px;

  &__item {
    width: 33.333%;
    padding: 15px;
    text-align: center;

    &--disabled {
      opacity: 0.35;

      a {
        pointer-events: none;
      }
    }
  }
}

.complete-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 4;

.btn-close {
  position: fixed;
  top: 20px;
  right: 10px;
  width: 60px;
  height: 60px;
  z-index: 4;

  a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(#292F43, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 50%;
  }
}

  .image {
    max-width: 64px;
    width: 100%;
    margin: 0 auto;
    transform: translate(-13%, 0);
  }

  .text {
    font-size: 20px;
    color: #fff;
    text-align: center;
    margin: 24px 0 0 0;

    p {
      white-space: pre;
    }
  }

}