.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 90px 28px 0 28px;
  overflow-y: auto;
  z-index: 3;

  &__close {
    position: fixed;
    top: 20px;
    right: 10px;
    width: 60px;
    height: 60px;

    a {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(#292F43, 0.5);
      border: 1px solid rgba(255, 255, 255, 0.4);
      border-radius: 50%;
    }
  }

  &__head {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__image {
    width: 120px;
    height: 116px;
    text-align: center;
  }

  &__title {
    display: block;
    width: calc(100% - 120px);
    font-size: 20px;
    padding: 0 0 0 20px;
    color: #fff;
    line-height: 1.5;
    user-select: none;
    white-space: pre;
  }


  &__qr {
    margin: 16px 0 0 0;

    &-image {
      width: 240px;
      margin: 0 auto;
    }

    &-text {
      padding: 20px 0 0 0;
      text-align: center;
      user-select: none;

      p {
        font-size: 18px;
        color: #fff;
        line-height: 1.5;
        white-space: pre;
      }
    }
  }

  &__bottom {
    margin: 20px 0 0 0;
    text-align: center;
    user-select: none;

    small {
      font-size: 12px;
      color: #fff;
      line-height: 1.5;
      white-space: pre;
    }

    .btn {
      position: relative;
      width: 100%;
      height: 70px;
      margin: 20px 0 0 0;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        font-size: 14px;
        color: #fff;
        background: linear-gradient(90deg, rgba(5, 19, 50, 0) 0%, rgba(5, 19, 50, 0.4) 13%, rgba(5, 19, 50, 0.4) 87.5%, rgba(5, 19, 50, 0) 100%);
        line-height: 1.5;
      }

      img {
        margin: 0 10px 0 0;
      }
    }
  }
}