.page-info {
  background-color: #0c1a33;
  // background-color: #0a152b;
}

.container {
  padding: 0 20px;
}

.hero {
  position: relative;

  &__image {
    img {
      width: 100%;
    }
  }

  &__textbox {
    width: 100%;
    position: absolute;
    left: 50%;
    bottom: 70px;
    z-index: 2;
    transform: translate(-50%, 0);
  }

  &__heading {
    position: relative;
    font-size: 20px;
    color: #fff;
    text-align: center;
    line-height: 1.8;

    small {
      display: block;
      font-size: 13px;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: -11px;
      left: 50%;
      width: 40px;
      height: 1px;
      background-color: #fff;
      transform: translate(-50%, 0);
    }
  }

  &__text {
    margin: 25px 0 0 0;
    font-size: 16px;
    text-align: center;
    color: #fff;
    line-height: 1.8;
  }
}

.block {
  padding: 60px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.3);

  &:first-child {
    border-top: none;
  }

  &__title {
    font-size: 18px;
    color: #fff;
    line-height: 1.8;
  }

  &__text {
    margin: 20px 0 0 0;
    font-size: 16px;
    font-style: initial;
    color: #fff;
    line-height: 1.8;
  }

  &__footnote {
    display: block;
    margin: 60px 0 0 0;
    font-size: 12px;
    color: #fff;
    line-height: 1.8;
  }
}