@import '../base//vars';

.page-goal {
  height: 100%;
  height: -webkit-fill-available;
}

.goal {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    height: -webkit-fill-available;
  }

  &__top {
    width: 100%;
    margin: 30px 0 0 0;
    text-align: center;

    img {
      min-width: 240px;
      width: 60%;
    }
  }

  &__head {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 32px 0 0 0;
  }

  &__image {
    width: 120px;
    text-align: center;
  }

  &__title {
    display: block;
    width: calc(100% - 120px);
    font-size: 20px;
    padding: 0 0 0 20px;
    color: #fff;
    line-height: 1.5;
    user-select: none;
    white-space: pre;
  }


  &__main {
    margin: 20px 0 0 0;
  }

  &__qr {
    margin: 16px 0 0 0;

    &-image {
      width: 234px;
      margin: 0 auto;
    }

    &-text {
      padding: 20px 0 0 0;
      text-align: center;
      user-select: none;

      p {
        font-size: 18px;
        color: #fff;
        line-height: 1.5;
        white-space: pre;
      }
    }

    &-small {
      text-align: center;
      margin: 6px 0;
      user-select: none;

      small {
        font-size: 12px;
        color: #fff;
        white-space: pre;
      }
    }
  }

  &__bottom {
    width: 100%;
    margin: auto 0 0 0;

    .btn {
      position: relative;
      width: 100%;
      height: 70px;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        font-size: 18px;
        color: #fff;
        background: linear-gradient(90deg, rgba(21, 44, 122, 0) 0%, rgba(21, 44, 122, 0.4) 13%, rgba(21, 44, 122, 0.4) 87.5%, rgba(21, 44, 122, 0) 100%);
        line-height: 1.5;
      }

      img {
        margin: 0 10px 0 0;
      }
    }
  }
}