.timer {
  position: absolute;
  bottom: 12px;
  right: -8px;
  width: 73px;
  overflow: hidden;

  &__arrow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
  }

  &__bg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 58px;
    height: 58px;
    transform: translate(-50%, -50%);
    z-index: 1;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__base {
    width: 100%;
  }
}

#timer-circle {
  $pi: 3.14159265358979;
  $radius: 14.5;
  $diameter: $radius * 2;
  $circumference: $diameter * $pi;

  transform-origin: center;
  stroke-width: $diameter;
  stroke-dasharray: $circumference;
  // stroke-dashoffset: 0;
  transform: rotate(-90deg);
}