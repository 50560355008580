.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 28px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow-y: auto;
  z-index: 3;

  &__close {
    position: fixed;
    top: 20px;
    right: 10px;
    width: 60px;
    height: 60px;

    a {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(#292F43, 0.5);
      border: 1px solid rgba(255, 255, 255, 0.4);
      border-radius: 50%;
    }
  }

  &__image {
    max-width: 64px;
    width: 100%;
    margin: 0 auto;
    transform: translate(-13%, 0);
  }

  &__text {
    text-align: center;
    margin: 35px 0 0 0;

    p {
      font-size: 20px;
      color: #fff;
      line-height: 1.5;
    }
  }
}