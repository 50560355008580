.page-index {
  position: relative;
  width: 100%;
  height: 100%;
  height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  height: -webkit-fill-available;
  overflow: hidden;
}

.logo {
  text-align: center;
  padding: 70px 0 0 0;
}

.select {
  $this: &;
  margin: auto 0 0 0;
  padding: 0 0 60px 0;
  text-align: center;

  &__block {
    small {
      display: block;
      margin: 40px 0 0 0;
      color: #fff;
      font-size: 11px;
      line-height: 1.5;
    }
  }

  &__text {
    font-size: 18px;
    color: #fff;
    line-height: 1.5;
  }

  &__options {
    margin: 40px 0 0 0;

    &--hor {
      display: flex;
      justify-content: center;
    }
  }

  &__option {
    width: 100%;
    height: 60px;

    + #{$this}__option {
      margin: 13px 0 0 0;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      text-align: center;
      color: #fff;
      font-size: 22px;
      background-color: rgba(9, 17, 44, 0.75);
      border: 1px solid rgba(255, 255, 255, 0.4);
      border-radius: 10px;
    }

    &--round {
      width: 120px;
      height: 120px;

      + #{$this}__option {
        margin: 0 0 0 30px;
      }

      a {
        font-size: 24px;
        border-radius: 50%;
      }
    }
  }
}

.btn {
  max-width: 300px;
  width: 100%;
  height: 60px;
  margin: 30px 0 0 0;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #fff;
    text-align: center;
    border: 2px solid #fff;
    border-radius: 60px;
  }
}

.anim-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.99);
  z-index: 3;
  pointer-events: none;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 28px;
  background-color: rgba(9, 17, 44, 0.95);
  overflow-y: auto;
  z-index: 3;

  &__close {
    position: fixed;
    top: 20px;
    right: 10px;
    width: 60px;
    height: 60px;

    a {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(#292F43, 0.5);
      border: 1px solid rgba(255, 255, 255, 0.4);
      border-radius: 50%;
    }
  }

  &__text {
    text-align: center;

    p {
      font-size: 20px;
      color: #fff;
      line-height: 1.5;
    }
  }

  &__options {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 30px 0 0 0;
  }

  &__option {
    width: 100%;
    height: 60px;
    margin: 0 12px;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      text-align: center;
      color: #fff;
      font-size: 20px;
      background-color: rgba(9, 17, 44, 0.75);
      border: 1px solid rgba(255, 255, 255, 0.4);
      border-radius: 10px;
    }
  }
}