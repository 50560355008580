@import '../base/vars';

.page-idle {
  height: 100%;
  height: -webkit-fill-available;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  height: -webkit-fill-available;
}

.top {
  width: 100%;
  margin: 30px 0 0 0;
  text-align: center;

  img {
    min-width: 240px;
    width: 60%;
  }
}

.mid {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 200px;
  margin: auto 0 0 0;
}

.btn-camera {
  position: relative;
  min-width: 200px;
  width: 50%;
  min-height: 200px;
  z-index: 1;

  a {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;

    img {
      display: block;
      margin: 0 auto;
    }

    &::before {
      content: "";
      display: none;
      width: 130%;
      height: 130%;
      max-width: 520px;
      max-height: 520px;
      position: absolute;
      top: 50%;
      left: 50%;
      background: radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 70%);
      transform: translate(-50%, -50%);
      border-radius: 50%;
      z-index: -1;
      animation: buttonBgAnim 2000ms ease-in-out infinite alternate;
      @include sm {
        display: block;
      }
    }

    &::after {
      content: "";
      display: none;
      width: 120%;
      height: 120%;
      max-width: 480px;
      max-height: 480px;
      position: absolute;
      top: 50%;
      left: 50%;
      background: radial-gradient(circle, rgba(255, 255, 255, 0.85) 25%, rgba(255, 255, 255, 0) 70%);
      transform: translate(-50%, -50%);
      border-radius: 50%;
      z-index: -1;
      animation: buttonBgAnim 2000ms ease-in-out infinite alternate;
      @include sm {
        display: block;
      }
    }
  }

}

.bottom {
  width: 100%;
  margin: auto 0 0 0;
}

.btnwrap {
  margin: 0 0 20px 0;
}

.btn-goal,
.btn-sound {
  width: 40px;
}

.btn-sound {
  margin: 10px 0 0 0;
}

.btn {
  position: relative;
  width: 100%;
  height: 70px;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 18px;
    color: #fff;
    background: linear-gradient(90deg, rgba(21, 44, 122, 0) 0%, rgba(21, 44, 122, 0.4) 13%, rgba(21, 44, 122, 0.4) 87.5%, rgba(21, 44, 122, 0) 100%);
    line-height: 1.5;
  }

  img {
    margin: 0 10px 0 0;
  }
}

@keyframes buttonBgAnim {
  0% {
    transform: translate(-50%, -50%) scale(0.8);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}