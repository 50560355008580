* {
  box-sizing: border-box;
}

html {
  overscroll-behavior-y: none;
}

body {
  position: relative;
  color: color(text);
  font-size: 14px;
  font-family: "Sawarabi Gothic", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  line-height: 1.5;
  overflow-x: hidden;
  z-index: 1;

  &::-webkit-scrollbar {
    // display: none;
  }

  &.no-scroll {
    overflow-y: hidden;
  }
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: color(text);
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  font-weight: 400;
}

figure {
  margin: 0;
}

.sp-hide {
  display: block;
  @include md {
    display: none;
  }
}

.sp-show {
  display: none;
  @include md {
    display: block;
  }
}

.app {
  position: relative;
  height: 100vh;
  height: -webkit-fill-available;
  height: 100dvh;
  width: 100%;
  margin: 0 auto;
  z-index: 1;
}

.transition-wrap {
  height: 100%;
  height: -webkit-fill-available;
}

.container {
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
}

.bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url('../../images/common/bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;
}

.btn {
  a {
    padding: 40px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #d9d9d9;
  }
}